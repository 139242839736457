import authApi from "@/api/auth";

export default {
  async loadToken({ commit }) {
    await commit("loadToken");
  },
  updateUser({ commit }, payload) {
    commit("updateUser", payload);
  },
  async requestPasswordReset(state, payload) {
    await authApi.requestPasswordReset(payload);
  },
  async passwordReset(state, payload) {
    await authApi.passwordReset(payload);
  }
};
